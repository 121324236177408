import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/seo";
import HeaderPress from "../components/HeaderPress";
import DownloadAll from "../components/DownloadAll";
import CardPressMedia from "../components/Cards/CardPressMedia";

import companyProfile from "../static/docs/company-profile.pdf";
import companyProfileCover from "../static/docs/company-profile-cover.jpg";

import "../utils/general.scss";
import "../utils/page-press.scss";

const PressMedia = ({ pageContext }) => {
    const images = [
        {
            image: companyProfileCover,
            link: companyProfile,
            text: "Company Profile di Pam Panorama"
        }
    ];

    const onDownloadAll = () => {
        images.map(item => {
            let element = document.createElement('a');
            element.setAttribute('href', item.link);
            element.setAttribute('download', item.text);
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        });
        return;
    }

    return (
        <Layout pageContext={pageContext}>
            <SEO title={`${pageContext.localeResources['press.media.title']} | ${pageContext.localeResources['menu.press']}`}>
                {typeof window !== 'undefined' &&
                    <link href={window.location} rel="canonical" />
                }
            </SEO>

            <HeaderPress title={pageContext.localeResources['press.media.title']} />

            <section className="section-press colorful-bg">
                <div className="container section">
                    {images.map((item, index) => (
                        <CardPressMedia 
                            key={index}
                            link={item.link}
                            image={item.image}
                            text={item.text}
                            pageContext={pageContext}
                        />
                    ))}
                    
                    {images.length > 1 && 
                        <DownloadAll onClick={onDownloadAll} pageContext={pageContext} />
                    }

                    {images.length < 1 &&
                        <p style={{width: "100%", textAlign: "center"}}>{pageContext.localeResources['feedback.noMedia']}</p>
                    }
                </div>
            </section>
        </Layout>
    )
}

export default PressMedia
