import React from "react";
import download from "../../images/download.svg";
import "./style.scss";

const DownloadAll = ({onClick, pageContext}) => (
    <button className="see-more ie" onClick={onClick} onKeyDown={onClick} alt="scarica tutte le immagini del Media Gallery" aria-label="scarica tutte le immagini del Media Gallery" >
        <img src={download} className="icon" alt="download" />
        {pageContext.localeResources['cta.downloadAll']}
    </button>
)

export default DownloadAll
