import React from "react"
import image from "../../images/section-story.jpg"

import "./style.scss"

const HeaderPress = ({ title }) => (
    <section className="press-info">
        <div className="info">
            <div
                className="contatti-info ie"
                data-aos="fade-right"
                data-aos-offset="200"
            >
                <h2>{title}</h2>
                <h5 className="subtitle gray">Ufficio stampa</h5>
                <p>
                    Alessio Melillo{" "}
                    <a
                        href="mailto:alessio@the3floor.com"
                        aria-label={`scrivi una email a alessio@the3floor.it`}
                    >
                        alessio@the3floor.it
                    </a>{" "}
                    <br />
                    Filippo Solaroli{" "}
                    <a
                        href="mailto:filippo@the3floor.com"
                        aria-label={`scrivi una email a filippo@the3floor.it`}
                    >
                        filippo@the3floor.it
                    </a>{" "}
                    <br />
                    3rd Floor Public Relations <br />
                    Via Gian Battista Vico 42, 20123 Milano MI, Italia
                    <br />
                    <a
                        href="tel:+390287168894"
                        aria-label={`chiama il numero 0287168894`}
                    >
                        +39 0287168894
                    </a>
                    <br />
                    <a
                        href="http://the3floor.com"
                        aria-label={`visita il the3floor.com`}
                    >
                        the3floor.com
                    </a>
                </p>
                {/* <h5 className="subtitle gray">Responsabile corporate marketing <br/>e relazioni con l'esterno</h5>
                <p>
                    Dominga Fragassi <a href="mailto:dominga_fragassi@gruppopam.it" aria-label={`scrivi una email a dominga_fragassi@gruppopam.it`}>dominga_fragassi@gruppopam.it</a> <br />
                </p> */}
            </div>
        </div>
        <div
            className="header rounded"
            style={{ backgroundImage: `url(${image})` }}
        ></div>
    </section>
)

export default HeaderPress
