import React from "react";
import download from "../../../images/download.svg";
import "../style.scss";
import "./style.scss";

const CardPress = ({image, link, text, pageContext}) => (
    <div className="card card-press-media rounded ie">
        <img src={image} alt={text} />
        <span>
            <h5 className="subtitle">{text}</h5>
            <a href={link} download aria-label={`scarica file ${text}`}>
                {pageContext.localeResources['cta.download']} <img src={download} className="icon" alt="download icon" />
            </a>
        </span>
    </div>
)

export default CardPress
